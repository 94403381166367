<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex flex-row justify-content-end align-items-center">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <vue-good-table
      :columns="columns"
      :rows="charges"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'time'"
          class="text-nowrap"
        >
          <span class="text-nowrap">
            {{ props.row.date.date.split(' ')[0] + ' ' + props.row.date.date.split(' ')[1].slice(0, 5) }}</span>
        </span>
        <span v-else-if="props.column.field === 'amount'">
          {{ props.row.amount.amount }} {{ props.row.amount.currency.symbol }}
        </span>
        <span v-else-if="props.column.field === 'impression'">
          {{ props.row.stats ? props.row.stats.daily.impressions : '/'}}
        </span>
        <span v-else-if="props.column.field === 'clicks'">
          {{ props.row.stats ? props.row.stats.daily.clicks : '/'}}
        </span>
        <span v-else-if="props.column.field === 'conversions'">
          {{ props.row.stats ? props.row.stats.daily.conversions : '/'}}
        </span>
        <span v-else-if="props.column.field === 'videoViews'">
          {{ props.row.stats ? props.row.stats.daily.videoViews : '/'}}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'Charges',
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      transactions: [],
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Time',
          field: 'time',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Amount',
          field: 'amount',
        },
        {
          label: 'Impressions',
          field: 'impression',
        },
        {
          label: 'Clicks',
          field: 'clicks',
        },
        {
          label: 'Conversions',
          field: 'conversions',
        },
        {
          label: 'Video Views',
          field: 'videoViews',
        },
      ],
      searchTerm: '',
      accounts: [],
      charges: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted () {
    this.fetchAccounts()
  },
  methods: {
    fetchAccounts () {
      this.$store.dispatch('fetchAccounts').then(() => {
        this.accounts = this.$store.state.accountsStore.accounts
        let clientAccountsIds = this.accounts.map(acc => acc.id)
        this.fetchCharges(clientAccountsIds)
      }).catch(error => {
        let msg = error.message
        if (error.response) {
          msg = error.response.data.msg
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: msg,
            icon: 'InfoIcon',
            variant: 'danger',
          },
        }, {
          position: 'top-center',
        })
      })
    },
    async fetchCharges (clientAccountsIds) {
      this.$store.dispatch('fetchCharges', clientAccountsIds).then(() => {
        this.charges = this.$store.state.accountsStore.charges
      }).catch(error => {
        let msg = error.message
        if (error.response) {
          msg = error.response.data.msg
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: msg,
            icon: 'InfoIcon',
            variant: 'danger',
          },
        }, {
          position: 'top-center',
        })
      })
    }
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
